<template>
	<v-app app id="ucp.ui.kiosk">
		<div v-if="accountActive">
			<v-app-bar app block class="appBar">
				<v-container class="pa-0 py-1">
					<v-row justify="center" align="center" class="d-flex align-items-center">
						<v-col cols="4" sm="4" md="4" class="text-center text-sm-left">
							<div>Signed in: <b class="signInText">{{ $auth.userProfile.name }}</b></div>
						</v-col>
						<v-col cols="4" sm="4" md="4" class="text-center text-sm-center py-0 py-sm-2" v-if="$prisoner">
							<img v-if="$siteKey == 'serdov'" id="home-logo" width="100" src="../assets/SD_Inspire_Logo.png">
						</v-col>
						<v-col cols="4" sm="4" md="4" class="text-center text-sm-right">
							<div>Points available: <b class="pointsAvailable">{{ balance }}</b></div>
						</v-col>
						<v-col cols="4" sm="4" md="4" class="text-center text-sm-right" v-if="!$prisoner">
							<v-btn @click="$auth.signOut()">
								<v-icon>mdi-logout-variant</v-icon>
								Logout
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-app-bar>
		</div>
		<v-container class="white">
			<div class="d-flex white navBtnHolder">
				<v-btn @click="selectRoute(item.path)" link :id="item.title" v-for="item in items" x-large class="primary flex-grow-1 elevation-9 navBtn navHighlight justify-center align-center text-center" :class="checkRoute(item)" :key="item.title">
					<v-icon class="primary border-b-1 mr-2">{{ item.icon }}</v-icon>
					<span :class="item.class">{{ item.title }}</span>
				</v-btn >
			</div>
		</v-container>

		<slot></slot>
	</v-app>
</template>

<script>

	export default {
		// eslint-disable-next-line
		name: "Nav",
		data(){
			return {
					drawer: true,
					accountActive: true,
					items: [
						{
							title: 'Home',
							path: '/Home',
							items: [],
							icon: 'mdi-home',
							class: "$route.path.includes('/Home') ? 'selected' : 'deselected'"
						},
						{
							title: 'Points',
							path: '/Points',
							items: [],
							icon: 'mdi-coin-outline',
							class: "$route.path.includes('/Points') || $route.path.includes('/Transactions') || $route.path.includes('/Shop') ? 'selected' : 'deselected'"
						},
					],
					scroll: "",
					height: "10vh",
					active: false,
					balance: null,
					name: null
			}
		},
		methods: {
			initialize() {
				if(typeof this.$auth.userProfile.role !== "undefined"){
					if (this.$auth.userProfile.role == 'staff' || this.$auth.userProfile.role == 'super') {
						this.$prisoner = false
					}
					else {
						this.$prisoner = true
					}
					this.getAccount();
				}
				else{
					setTimeout(this.initialize, 250);
				}
			},
			async getAccount() {
				try {
					let response
					if (this.$prisoner) {
						response = await this.$crisApi.account.getPreAuth(this.$auth.userProfile.sentence);
					}
					else {
						response = await this.$crisApi.account.getPreAuthStaff(this.$auth.userProfile.name)
					}
					this.accountActive = response.data.active;
					this.$emit("userInfoUpdate", response.data)
					this.balance = response.data.balance;
					this.name = this.$auth.userProfile.name
				} 
					catch (error) {
						console.error(error);
					}
			},
			onResize () {
				var router = document.getElementsByClassName("router")
				router.height = (( window.innerHeight + 100) + "px")
				this.scroll = "overflow-y-auto"
			},
			selectRoute(routeName){
				if (this.$route.path != routeName) {
					this.$router.push(routeName).catch(() => {})
				}
			},
			checkRoute(item){
				if(item.path === this.$route.path)
				{
					return 'highlighted'
				}
			}
		},
		created () {
			this.initialize()
		}
	}
</script>
