<template>
  <NavVue>
    <div v-if="showLoading" class="loading-container">
      <v-progress-circular indeterminate color="black"></v-progress-circular>
      <br />
      <p>Loading Incentives, Please Wait...</p>
    </div>
    <div v-else class="container" id="shopView">
      <v-row id="titleBar" no-gutters>
        <v-col class="pt-0 pb-4 px-0" cols="12" md="6" sm="6">
          <a
            v-if="parentIncentiveID == null"
            @click="selectRoute('/Points')"
            class="rtrnHeader"
          >
            <h3 class="sizeTwo">
              <v-icon class="pb-1" size="30">mdi-arrow-left</v-icon>&nbsp;
              Return to points summary
            </h3>
          </a>
          <a v-else @click="parentIncentiveID = null" class="rtrnHeader">
            <h3 class="sizeTwo">
              <v-icon class="pb-1" size="30">mdi-arrow-left</v-icon>&nbsp;
              Return to previous page
            </h3>
          </a>
        </v-col>

        <v-col class="pa-2" cols="12" md="6" sm="6"> </v-col>
      </v-row>
      <div class="itemContainer">
        <div v-if="browse">
          <div
            class="shopSquare mb-4"
            v-bind:key="item.incentiveID"
            v-for="item in parentIncentive"
          >
            <v-card
              class="item shopBtn mx-auto pa-4"
              @click="checkForChild(item)"
              :class="{ true: 'primary white--text', false: 'not-active' }[item.cost <= balance && item.purchaseAvailable == true || (item.hasChildren==true)]"
            >
              <v-row
                class="d-flex items-cebter"
                align="center"
                justify="center"
              >
                <v-col cols="12" md="7" sm="6">
                  <h2 class="headline text-left mb-1">{{ item.title }}</h2>
                  <p
                    class="subText sizeThree"
                    color="white"
                    align="left"
                    justify="top"
                  >
                    {{ item.description }}
                  </p>
                </v-col>
                <v-col class="text-left text-sm-right" cols="6" md="4" sm="5">
                  <h2 class="sizeThree pointCost">
                    <b
                      class="sizeFive"
                      :class="{ true: 'primary', false: 'not-active-text ' }[item.cost <= balance && item.purchaseAvailable == true]"
                      style="font-size: 2rem"
                    >
                      {{ item.cost }}</b
                    >
                    points
                  </h2>
                  <h4
                    class="sizeTwo"
                    :class="{ true: '1purchaseDate', false: 'not-active-text ' }[item.cost <= balance && item.purchaseAvailable == true]"
                  >
                    {{ item.nextPurchaseIndicator }}
                  </h4>
                  <h2 v-if="item.price > 0" class="sizeThree pointCost">
                    <b class="sizeFive" style="font-size: 2rem"
                      >£{{ item.price.toFixed(2) }}</b
                    >
                  </h2>
                </v-col>
                <v-col class="text-center" cols="6" sm="1" md="1" lg="1">
                  <img
                    v-if="item.array != null"
                    class="sizeTwo shopLogo"
                    id="shopLogo"
                    @click.stop="enlargeImage(item.imagePath)"
                    :src="item.imagePath"
                    style="pointer-events: all !important;"
                  />
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>

        <v-dialog
          persistant
          v-model="dialog"
          id="itemDetails"
          v-if="dialog"
          persistent
          max-width="500"
        >
          <v-card>
            <v-card-title class="headline">{{
              selectedItem.title
            }}</v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col class="pt-2 pb-6" cols="12" md="12">
                  <div
                    class="description"
                    v-html="selectedItem.description"
                  ></div>
                </v-col>
                <v-col class="py-0" cols="12" md="12">
                  Your balance: {{ balance }}
                </v-col>
                <v-col class="py-1" cols="12" md="12">
                  This purchase: {{ selectedItem.formattedAmount }}
                </v-col>
                <v-col class="py-1" cols="12" md="12" v-if="selectedItem.parcel">
                  <v-combobox
                    v-model="purchasableParcel"
                    :items="selectedItem.parcelOptions.split('\n')"
                    :label="`Select up to ${selectedItem.parcelOptionCount} items`"
                    multiple
                    deletable-chips
                    small-chips
                    clearable
                    rounded 
                    v-on:input="validateSelection()"
                  ></v-combobox>
                </v-col>
                <v-col
                  v-if="selectedItem.price > 0"
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <i class="secondary--text">
                    This item has a price of: £{{
                      selectedItem.price.toFixed(2)
                    }}
                  </i>
                </v-col>
              </v-row>
              {{selectedItem.orderPDF}}
            </v-card-text>
            <v-divider class="mt-1 mb-0" />
            <v-card-actions class="pb-2 px-2">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="red darken-1"
                @click="
                  dialog = false;
                  resetSelectedItem();
                "
                >cancel</v-btn
              >
              <!--@click="
                  purchase();
                  dialog = false;
                "-->
              <!--@click="selectedItem.orderPDF ?  pdfDialog=true : purchase()"-->
              <v-btn
                v-if="!serverMsg && !errorMsg && !successMsg"
                color="white darken-1 primary"
                @click="dialog=false, selectedItem.isDovegateHamper ? pdfDialog=true : purchase()"
                >Purchase</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="pdfDialog" width="700" height="700" style="overflow:hidden">
          <v-card class="mb-4 py-0 px-0" v-if="neededInformation">
            <v-card-title class="justify-start d-flex flex-nowrap px-8 py-4 primary white--text text-h5">
              <v-icon large class="white--text mr-4">mdi-file-eye</v-icon>
              <div>Purchase Details</div>
              <v-spacer></v-spacer>
              <v-icon large class="white--text mr-4" @click="pdfDialog=false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <v-row class="mt-4">
                <v-col 
                  class="pt-2 pb-6" cols="6" md="6" 
                  v-for="(field, index) in informationDataType" :key="index"
                >
                  <div v-if="!autofillPDFKeys.includes(field.key) && field.key != '<PrisonerSignature>'">
                    <v-textarea v-if="field.type=='TextArea'" :label="field.key.slice(1,-1)" v-model="neededInformation[field.key]">
                    </v-textarea>
                    <v-text-field v-else-if="field.type=='Text'" :label="field.key.slice(1,-1).replace(/([A-Z])/g, ' $1').trim()" v-model="neededInformation[field.key]">
                    </v-text-field>
                    <v-menu
                      v-else-if="field.type=='Date'"
                      ref="datePicker"
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :return-value.sync="datePicker"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computeTempDate"
                          :label="key.slice(1,-1).replace(/([A-Z])/g, ' $1').trim()"
                          prepend-icon="mdi-calendar"
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          @click="neededKey=field.key"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="neededInformation[field.key]"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="datePicker = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="datePicker = false"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <span v-if="'<PrisonerSignature>' in neededInformation" style="display: inline-block;">
                  <input type="checkbox" v-model="neededInformation['<PrisonerSignature>']" style="display: inline-block;" class="mr-2">
                  <label style="display: inline-block; vertical-align: middle; width: 90%">By checking this box I confirm that:<br />
- I understand that this hamper/gift will only be sent to the person and address provided<br />
- I am not under a restraining order, no contact agreement or other restrictions which prevent me
from contacting the person named above, or any other individual who lives at the address provided.<br />
- I declare that this hamper/gift is intended for the person named above and I understand that I may
be removed from the Reward Scheme if this is found not to be the case.</label>
                </span>
                <!-- <v-col class="pt-2 pb-6" cols="4" md="4" v-for="(item, index) in Object.keys(neededInformation)" :key="index">
                  <v-textarea v-if="item.includes('Message')" :label="item.slice(1,-1)" v-model="neededInformation[item]">
                  </v-textarea>
                  <v-text-field v-else :label="item.slice(1,-1).replace(/([A-Z])/g, ' $1').trim()" v-model="neededInformation[item]">
                  </v-text-field>
                </v-col> -->
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="white darken-1 primary"
                @click="submitPDF()"
                :disabled="(Object.values(neededInformation).includes(null) || Object.values(neededInformation).includes('')) && neededInformation['<PrisonerSignature>'] == false"
              >
                Submit</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
          v-model="successMsg"
          :timeout="timeout"
          v-if="successMsg"
          color="green"
          multiline
        >
          Purchase Request Received
          <v-btn
            class="btnNoStyles"
            color="white"
            text
            @click="successMsg = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar
          v-model="errorMsg"
          :timeout="timeout"
          v-if="errorMsg"
          color="red"
          multiline
        >
          There was a problem with your purchase. Please try again later.
          <v-btn
            class="btnNoStyles"
            color="white"
            text
            @click="errorMsg = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-row>
          <v-col>
            All awards are subject to the Terms and Conditions set out in the
            Community Reward Incentive Scheme
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="imageDialog" width="50%">
      <v-img
        contain
        aspect-ratio="1.7"
        :src="imageSrc"
        class="grey darken-4 text-right"
      >
        <v-spacer />
        <v-btn
          class="ma-1"
          small
          color="white"
          icon
          @click="imageDialog = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-img>
    </v-dialog>
  </NavVue>
</template>

<script>
import NavVue from "./Nav.vue";
import jsPDF from 'jspdf'

export default {
  // eslint-disable-next-line
  name: "Shop",
  computed: {
    parentIncentive() {
      if (this.parentIncentiveID != null) {
        return this.items.filter(
          item => item.parentIncentiveID === this.parentIncentiveID
        );
      } else {
        return this.items.filter(item => item.parentIncentiveID === null);
      }
    },
    computeTempDate(){
      return this.formatDate(this.neededInformation[this.neededKey]);
    },
  },
  data() {
    return {
      purchasableParcel: [],
      user: null,
      accountID: null,
      openId: null,
      transactions: [],
      suspensionEndDate: null,
      points: 75,
      browse: true,
      parentIncentiveID: null,
      selectedItem: null,
      serverMsg: false,
      successMsg: false,
      errorMsg: false,
      balance: null,
      formattedBalance: null,
      items: [],
      dialog: false,
      timeout: 5000,
      path: "",
      imageSrc: "",

      imageDialog: false,
      showLoading: true,
      pdfDialog: false,
      neededInformation: {"<Recipient>": "", "<Relationship>": "", "<Address>": "", "<PrisonerSignature>": "", "<Message to Recipient>": ""},
      currentPDF: null,
      informationDataType: [
        { 
          "type": "Text",
          "key": "<Recipient>"
        },
        { 
          "type": "Text",
          "key": "<Relationship>"
        },
        { 
          "type": "TextArea",
          "key": "<Address>"
        },
        { 
          "type": "TextArea",
          "key": "<Message to Recipient>"
        }
      ],
      neededKey: null,
      datePicker: false,
      autofillPDFKeys: ["<PrisonerName>", "<PrisonerNumber>", "<PrisonerLocation>", "<IncentiveCode>", "<Date>"],
      account: null,
    };
  },
  components: {
    NavVue
  },
  methods: {
    initialize() {
      if(typeof this.$auth.userProfile.role !== "undefined"){
        if (this.$auth.userProfile.role == 'staff' || this.$auth.userProfile.role == 'super') {
          this.$prisoner = false
        }
        else {
          this.$prisoner = true
        }
        this.updateAccount();
      }
      else{
        setTimeout(this.initialize, 250);
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    async submitPDF(){
      var doc = new jsPDF();

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("Serco Business", 90, 10);
      doc.addImage("serco.png", "PNG", 150, 10, 45, 10);
      doc.setFontSize(15);
      doc.setTextColor("#f82224");
      doc.setFont("helvetica", "bold");
      doc.text("Dovegate", 10, 30);
      doc.setFont("helvetica", "bolditalic");
      doc.text("Inspire", 35, 30);
      doc.setFont("helvetica", "bold");
      doc.text("Reward Scheme", 54, 30);
      doc.setFontSize(14);
      doc.setTextColor("#000000");
      doc.text("Application to send a Hamper/Gift Externally", 10, 38);
      doc.setFontSize(12);
      doc.setFillColor("#d9d9d9");
      doc.rect(10, 42, 190, 5, "FD");
      doc.rect(10, 47, 190, 5);
      doc.line(10, 47, 200, 47);
      doc.line(77, 42, 77, 52);
      doc.line(118, 42, 118, 52);
      doc.line(159, 42, 159, 52);
      doc.setTextColor("#f82224");
      doc.setFont("helvetica", "normal");
      doc.text("Member name", 12, 46);
      doc.text("Prison number", 79, 46);
      doc.text("Wing and cell", 120, 46);
      doc.text("Hamper/Gift code", 161, 46);
      doc.setTextColor("#000000");
      doc.text(this.account.name, 12, 51);
      doc.text(this.account.sentenceNumber, 79, 51);
      doc.text(this.account.location.description, 120, 51);
      doc.text(this.selectedItem.title, 161, 51);
      doc.text("I would like to send a hamper/gift to:", 10, 60);
      doc.setFillColor("#d9d9d9");
      doc.rect(10, 65, 190, 5, "FD");
      doc.rect(10, 70, 190, 10);
      doc.line(58, 65, 58, 80);
      doc.line(106, 65, 106, 80);
      doc.setTextColor("#f82224");
      doc.text("Name(s)", 12, 69);
      doc.text("Relationship(s) to me", 60, 69);
      doc.text("Full address and Post Code", 108, 69);
      doc.setTextColor("#000000");
      doc.text(this.neededInformation['<Recipient>'], 12, 74);
      doc.text(this.neededInformation["<Relationship>"], 60, 74);
      doc.text(this.neededInformation["<Address>"], 108, 74);
      doc.text("I would like the hamper/gift to be sent with the following personal message:", 10, 89);
      doc.setFillColor("#d9d9d9");
      doc.rect(10, 94, 190, 5, "FD");
      doc.rect(10, 99, 190, 15);
      doc.setTextColor("#f82224");
      doc.text("Message Text(short messages only)", 12, 98);
      doc.setTextColor("#000000");
      doc.text(this.neededInformation["<Message to Recipient>"], 12, 103);
      doc.setFont("helvetica", "bold");
      doc.text("Declaration", 10, 125);
      doc.circle(11, 130, 1, "F");
      doc.setFont("helvetica", "normal");
      doc.text("I understand that this hamper/gift will only be sent to the person and address provided", 15, 131);
      doc.circle(11, 137, 1, "F");
      doc.text("I am not under a restraining order, no contact agreement or other restrictions which prevent me\nfrom contacting the person named above, or any other individual who lives at the address provided.", 15, 138);
      doc.circle(11, 147, 1, "F");
      doc.text("I declare that this hamper/gift is intended for the person named above and", 15, 148);
      doc.setFont("helvetica", "bold");
      doc.text("I understand that I may", 155, 148);
      doc.text("be removed from the Reward Scheme if this is found not to be the case.", 15, 153);
      doc.setFillColor("#d9d9d9");
      doc.rect(10, 158, 190, 5, "FD");
      doc.rect(10, 163, 190, 10);
      doc.line(136, 158, 136, 173);
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#f82224");
      doc.text("Signature", 12, 162);
      doc.text("Date", 138, 162);
      doc.setTextColor("#000000");
      doc.addFont("DarlingtonDemo-z8xjG.ttf", "Darl", "normal");
      doc.setFont("Darl", "normal");
      doc.setFontSize(20)
      doc.text(this.account.name, 12, 172);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12)
      doc.text(new Date().toLocaleDateString(), 138, 167);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(14);
      doc.text("NOW POST ON TO OMU IN THE ENVELOPE PROVIDED\n\nOffice Use Only", 10, 180);
      doc.setFontSize(12);
      doc.text("Public Protection Assessment", 10, 198);
      doc.setFont("helvetica", "normal");
      doc.text("I confirm that a full Public Protection Assessment has been completed regarding the above-named\nprisoner. I can confirm that:", 10, 205);
      doc.setDrawColor("#f82224");
      doc.rect(10, 219, 5, 5);
      doc.text("The hamper/gift may be sent to the person named above because there are no restrictions in place\npreventing contact with this person or the address provided by the prisoner.", 18, 220);
      doc.rect(10, 233, 5, 5);
      doc.text("The hamper/gift may", 18, 233);
      doc.setFont("helvetica", "bold");
      doc.text("NOT", 58, 233);
      doc.setFont("helvetica", "normal");
      doc.text("be sent to the person named above because there", 68, 233);
      doc.setFont("helvetica", "bold");
      doc.text("ARE", 164, 233);
      doc.setFont("helvetica", "normal");
      doc.text("restrictions", 175, 233);
      doc.text("in place preventing contact with this individual by the prisoner, and/or the prisoner is currently\non Public Protection Monitoring.", 18, 238);
      doc.setFillColor("#d9d9d9");
      doc.setDrawColor("#000000");
      doc.rect(10, 248, 190, 5, "FD");
      doc.rect(10, 253, 190, 10);
      doc.line(58, 248, 58, 263);
      doc.line(106, 248, 106, 263);
      doc.line(154, 248, 154, 263);
      doc.setTextColor("#f82224");
      doc.text("Staff Signature", 12, 252);
      doc.text("Position", 60, 252);
      doc.text("Additional Comments", 108, 252);
      doc.text("Date", 156, 252);
      doc.setFont("helvetica", "bold");
      doc.setTextColor("#000000");
      doc.text("Scheme Co-ordinator Use", 10, 270);
      doc.setFillColor("#d9d9d9");
      doc.rect(10, 275, 190, 5, "FD");
      doc.rect(10, 280, 190, 10);
      doc.line(106, 275, 106, 290);
      doc.line(154, 275, 154, 290);
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#f82224");
      doc.text("Action Taken", 12, 279);
      doc.text("Signature", 108, 279);
      doc.text("Date", 156, 279);
      //doc.save("hamper.pdf")
      this.purchase(btoa(doc.output()))
      this.pdfDialog = false;
    },
    async getPDF(filename){
      filename = "hamper";
      var response = await this.$pdfApi.pdf.getPDFTypes(this.$siteKey, filename);
      this.neededInformation = Object.fromEntries(Object.keys(response.data.fieldDict).map((c) => [c, null]));
      this.informationDataType = response.data.fieldDict;
      this.currentPDF = filename;
      if ("<PrisonerName>" in this.neededInformation) {
        this.neededInformation["<PrisonerName>"] = this.account.name
      }
      if ("<PrisonerNumber>" in this.neededInformation) {
        this.neededInformation["<PrisonerNumber>"] = this.account.sentenceNumber
      }
      if ("<PrisonerLocation>" in this.neededInformation) {
        this.neededInformation["<PrisonerLocation>"] = this.account.location.description
      }
      if ("<IncentiveCode>" in this.neededInformation) {
        this.neededInformation["<IncentiveCode>"] = this.selectedItem.title
      }
      if ("<Date>" in this.neededInformation) {
        var date = new Date()
        this.neededInformation["<Date>"] = date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear()
      }
    },
    validateSelection() {
      if (this.purchasableParcel.length > this.selectedItem.parcelOptionCount){
        this.purchasableParcel.pop();
      }
    },
    checkForChild(item) {
      if (item.hasChildren == true) {
        this.parentIncentiveID = item.incentiveID;
      } else {
        this.setSelectedItem(item);
        this.dialog = true;
      }
    },
    enlargeImage(iSrc) {
      this.imageSrc = iSrc;
      this.imageDialog = true;
    },
    selectRoute(routeName) {
      this.$router.push(routeName);
    },
    async getIncentives() {
      this.showLoading = true;
      try {
        var self = this;
        let response
        if (this.$prisoner) {
          response = await this.$crisApi.incentive.getAccountIncentives(this.$siteKey, this.accountID);
        }
        else {
          response = await this.$crisApi.incentive.getStaffAccountIncentives(this.$siteKey, this.accountID);
        }
        console.log(response)
        var data = [];
        switch(this.$auth.userProfile.role){
          case "prisoner":
            data = response.data.filter(element=>element.staffIncentive==false||element.staffIncentive==null);
            break;
          case "staff":
            data = response.data.filter(element=>element.staffIncentive==true);
            break;
          case "super":
            data = response.data.filter(element=>element.staffIncentive==true);
        }
        self.items = [];

        data.forEach(function(item) {
          var amount = item.cost;
          item.formattedAmount = Number(amount).toLocaleString();
          var imageData = item.array;
          var imageSource = "data:image/";

          if (item.array != null) {
            if (item.fileType == ".png") {
              imageSource += "png";
            } else if (item.fileType == ".jpeg" || item.fileType == ".jpg") {
              imageSource += "jpg";
            } else if (item.fileType == ".svg") {
              imageSource += "svg+xml";
            }

            imageSource += ";base64," + imageData;
          }
          item.imagePath = imageSource;
          self.items.push(item);
        });
      } catch (error) {
        console.error(error);
      }
      this.showLoading = false;
    },
    async updateAccount() {
      try {
        let response
        if (this.$prisoner) {
          response = await this.$crisApi.account.getPreAuth(this.$auth.userProfile.sentence);
        }
        else {
          response = await this.$crisApi.account.getPreAuthStaff(this.$auth.userProfile.name)
        }
        this.$siteKey = this.$prisoner ? this.$auth.userProfile.site : response.data.siteKey;
        this.accountID = this.$prisoner ? response.data.accountID : response.data.staffAccountID;
        this.balance = response.data.balance;
        this.account = response.data;
        this.getIncentives();
      } catch (error) {
        console.error(error);
      }
    },
    async purchase(pdf = null) {
      if (this.selectedItem.cost <= this.balance) {
        var insertTransaction = true;
        var messagePurchase = false
        if (
          this.$messagingIncentiveIds.includes(
            this.selectedItem.incentiveID
          )
        ) {
          messagePurchase = true
          try {
            var purchaseInformation = {
              sentenceNumber: this.user.SentenceNumber,
              numberOfTexts:
                this.$messagesPerPoint *
                this.selectedItem.cost
            };
            await this.$messagingApi.pins.purchase(
              purchaseInformation
            );
          } catch (error) {
            insertTransaction = false;
            console.error(error);
            this.serverMsg = false;
            this.errorMsg = true;
          }
        }

        if (insertTransaction) {
          try {
            var data = {
              description: this.selectedItem.title,
              transactionAmount: -this.selectedItem.cost,
              incentiveID: this.selectedItem.incentiveID,
              private: false,
              transactionMonetaryValue: this.selectedItem.price,
              status: messagePurchase ? 0 : null,
              forwardingAddress: this.selectedItem.forwardingAddress
            };
            if (this.selectedItem.parcel){
              data.description = data.description+" -> "+this.purchasableParcel.join(" ");
            }
            if (this.$prisoner) {
              data.accountID = this.accountID
            }
            else {
              data.staffAccountID = this.accountID
              data.staffTransaction = true
            }

            if (pdf){
              data.linkedPDF = pdf;
            }

            this.successMsg = false;
            this.errorMsg = false;
            this.serverMsg = true;
            await this.$crisApi.transaction.purchase(this.$siteKey, data);
            this.serverMsg = false;
            this.updateAccount();
            this.successMsg = true;
          } catch (error) {
            console.error(error);
            this.serverMsg = false;
            this.errorMsg = true;
          }
        }
      } else {
        this.errorMsg = true;
      }
    },

    setSelectedItem(item) {
      this.selectedItem = item;
      var amount = this.selectedItem.cost;
      this.selectedItem.formattedAmount = Number(amount).toLocaleString();
    },
    resetSelectedItem() {
      this.selectedItem = {};
      this.successMsg = false;
    },
    parseDate(value) {
      var datePart = value.substring(0, 10);
      var partsArray = datePart.split("/");

      var timePart = value.substring(11);
      var timesArray = timePart.split(":");

      return new Date(
        Date.UTC(
          partsArray[2],
          partsArray[1] - 1,
          partsArray[0],
          timesArray[0],
          timesArray[1],
          timesArray[2]
        )
      );
    }
  },
  created() {
    this.initialize()
  }
};
</script>
